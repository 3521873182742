// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const consumer = require('../channels/consumer').default;

let currentSelectedIndex = null;

const setupConnection = () => {
    consumer.subscriptions.create( 
      {
          channel: "BroadcastChannel",
      }, 
      {
          connected() {
          },
          
          disconnected() {
          },
          
          received(data) {
            if(data.uuid === window.uuid) {
                const proBtn = document.getElementById(`pro_btn_${data.item}`);
                proBtn.classList.remove("inline-flex");
                proBtn.classList.add("hidden");
                const textBtn = document.getElementById(`gen_btn_${data.item}`);
                textBtn.classList.remove("hidden");
                const playBtn = document.getElementById(`play_btn_${data.item}`);
                playBtn.classList.remove("hidden");
                playBtn.dataset.fileRef = data.encoded;
                const stopBtn = document.getElementById(`stop_btn_${data.item}`);
                stopBtn.classList.remove("hidden");
                const downBtn = document.getElementById(`down_btn_${data.item}`);
                downBtn.classList.remove("hidden");
                const promptRespDiv = document.getElementById(`prompt_resp_${data.item}`);
                promptRespDiv.innerHTML = data.prompt;
                promptRespDiv.classList.remove("hidden");
                const respDiv = document.getElementById(`resp_${data.item}`);
                respDiv.innerHTML = data.response;
                respDiv.classList.remove("hidden");
                const promptDiv = document.getElementById(`prompt_${data.item}`);
                promptDiv.classList.add("hidden");
            }
          }

        }
      
    );
}

const setupGenerateButtons = () => {
    const genButtons = document.querySelectorAll("[id*='gen_btn']");
    genButtons.forEach((btn) => {
        btn.addEventListener("click", (evt) => {
            const ref = evt.target.id;
            const index = ref.lastIndexOf("_");
            const selectedIndex = ref.substring(index + 1);
            const selected = document.getElementById(`pro_btn_${selectedIndex}`);
            const textBtn = document.getElementById(`gen_btn_${selectedIndex}`);
            const playBtn = document.getElementById(`play_btn_${selectedIndex}`);
            const stopBtn = document.getElementById(`stop_btn_${selectedIndex}`);
            const downBtn = document.getElementById(`down_btn_${selectedIndex}`);
            const respDiv = document.getElementById(`resp_${selectedIndex}`);
            const promptRespDiv = document.getElementById(`prompt_resp_${selectedIndex}`);
            const promptDiv = document.getElementById(`prompt_${selectedIndex}`);
            if(currentSelectedIndex == selectedIndex) {
                let player = document.getElementById('main-player');
                player.pause();
            }
            selected.classList.remove("hidden");
            promptDiv.classList.remove("hidden");
            selected.classList.add("inline-flex");
            textBtn.classList.add("hidden");
            playBtn.classList.add("hidden");
            stopBtn.classList.add("hidden");
            downBtn.classList.add("hidden");
            respDiv.classList.add("hidden");
            promptRespDiv.classList.add("hidden");
            respDiv.innerHTML = "";
        });
    });
}

const setupPlayerButtons = () => {
    const playButtons = document.querySelectorAll("[id*='play_btn']");
    playButtons.forEach((btn) => {
        btn.addEventListener("click", (evt) => {
            let player = document.getElementById('main-player');
            currentSelectedIndex = evt.target.dataset.indexref;
            player.pause();
            player.src = evt.target.dataset.fileRef;
            player.autoplay = true;
        });
    });
}

const setupStopButtons = () => {
    const stopButtons = document.querySelectorAll("[id*='stop_btn']");
    stopButtons.forEach((btn) => {
        btn.addEventListener("click", (evt) => {
            let player = document.getElementById('main-player');
            player.pause();
        });
    });
}

const setupDownloadButtons = () => {
    const downloadButtons = document.querySelectorAll("[id*='down_btn']");
    downloadButtons.forEach((btn) => {
        btn.addEventListener("click", (evt) => {
            const playBtn = document.getElementById(`play_btn_${evt.target.dataset.indexref}`);
            const fileUrl = playBtn.dataset.fileRef;
            window.open(fileUrl, '_blank');
        });
    });
}

document.addEventListener('turbolinks:load', function() {
    setupConnection();
    setupGenerateButtons();
    setupPlayerButtons();
    setupStopButtons();
    setupDownloadButtons();
});

document.addEventListener('turbolinks:before-cache', function() {
    try {
        let player = document.getElementById('main-player');
        player.src = "";
        player.pause();
    } catch (error) {

    }
});